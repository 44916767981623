<template>
  <div class="container content-p">
    <section class="account-preview-wrapper">
      <!-- Alert: No item found -->
      <b-alert variant="danger" :show="accountData === undefined">
        <h4 class="alert-heading">Error fetching account data</h4>
        <div class="alert-body">
          No account found with this account id. Check
          <b-link class="alert-link" :to="{ name: 'apps-accounting-receivable-list-pending' }"> Account List </b-link>
          for other accounts.
        </div>
      </b-alert>

      <b-row class="account-preview">
        <!-- Col: Left (Account Container) -->
        <b-col cols="12" xl="10" lg="9" md="12">
          <b-row>
            <b-col>
              <statistic-card-horizontal
                :fa-icon="displayAccountStatus().icon"
                :statistic="displayAccountStatus().text"
                statistic-title="Schedule Status"
                :color="displayAccountStatus().color"
              />
            </b-col>
            <b-col>
              <statistic-card-horizontal
                fa-icon="fa-solid fa-dollar-sign"
                :statistic="money(accountData.total)"
                statistic-title="Total Due"
                color="info"
              />
            </b-col>
            <b-col>
              <statistic-card-horizontal
                fa-icon="fa-solid fa-dollar-sign"
                :statistic="money(accountData.totalPaid)"
                statistic-title="Total Paid"
                color="success"
              />
            </b-col>
            <b-col>
              <statistic-card-horizontal
                fa-icon="fa-solid fa-dollar-sign"
                :statistic="money(accountData.balance)"
                statistic-title="Remaining Balance"
                :color="accountData.balance > 0 ? (accountData.balance < accountData.total ? `warning` : `primary`) : `success`"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12" lg="12" xl="4">
              <b-card>
                <div class="d-flex justify-content-between">
                  <h4 class="my-auto">
                    <b-badge variant="light-info">{{ studentData.myIpId }}</b-badge>
                    {{ studentData.fullName }}
                  </h4>

                  <b-button variant="primary" size="sm" :to="{ name: 'apps-students-view', params: { id: studentData.id } }">
                    View Student
                  </b-button>
                </div>

                <hr class="my-1" />

                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">Email</span>
                    </th>
                    <td class="pb-50">
                      <a class="float-right" :href="`mailto:${studentData.email}`">{{ studentData.email }}</a>
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">Phone #</span>
                    </th>
                    <td class="pb-50">
                      <a class="float-right" :href="`tel:${studentData.phoneNumber}`">{{ studentData.phoneNumber }}</a>
                    </td>
                  </tr>
                  <tr v-if="studentData.profile && studentData.profile.secret.ssn">
                    <th class="pb-50">
                      <span class="font-weight-bold">SSN</span>
                    </th>
                    <td class="pb-50">
                      <a class="float-right">{{ studentData.profile.secret.ssn }}</a>
                    </td>
                  </tr>
                </table>

                <div v-if="accountData.comments">
                  <hr />

                  <p>
                    <b>Comments : </b><br />
                    {{ accountData.comments }}
                  </p>
                </div>
              </b-card>
              <b-card v-if="profileData.id">
                <div class="d-flex justify-content-between">
                  <h4 class="my-auto">{{ title(profileData.attributes.fundingSource) }} / {{ title(profileData.status) }}</h4>

                  <b-button
                    v-if="profileData.session && profileData.session.type === 'pathway'"
                    variant="outline-info"
                    size="sm"
                    :to="{ name: 'apps-roster-profile-view', params: { id: profileData.session.id } }"
                  >
                    View Pathway Cohort
                  </b-button>

                  <!-- {{ profileData.session }} -->
                  <b-button
                    v-if="profileData.session && profileData.session.type === 'course'"
                    variant="outline-info"
                    size="sm"
                    :to="{ name: 'apps-roster-profile-view', params: { id: profileData.session.id } }"
                  >
                    View Course Section
                  </b-button>

                  <b-button variant="primary" size="sm" :to="{ name: 'apps-roster-profile-edit', params: { id: profileData.id } }">
                    Edit Profile
                  </b-button>
                </div>
              </b-card>
              <b-card v-if="cohortData.id">
                <div class="d-flex justify-content-between">
                  <h4 class="my-auto">{{ cohortData.name }}</h4>

                  <b-button
                    variant="primary"
                    size="sm"
                    class=""
                    :to="{ name: 'apps-accounting-receivable-cohort-view', params: { id: cohortData.id } }"
                  >
                    View AR Cohort
                  </b-button>
                </div>
              </b-card>
            </b-col>
            <b-col md="12" lg="12" xl="8">
              <!-- Account Payments -->
              <b-card-actions title="Payments" action-collapse>
                <b-table-lite
                  ref="refAccountPayments"
                  responsive
                  :items.sync="accountData.payments"
                  :fields="[
                    { key: 'date', label: 'Scheduled', thStyle: 'width:10%' },
                    { key: 'description', thStyle: 'width:50%' },
                    { key: 'amount', thStyle: 'width:15%' },
                    'paidAt',
                    'type',
                    'status',
                  ]"
                  thead-class="text-nowrap"
                  :tbody-tr-class="tablePaymentPastDue"
                  bordered
                  primary-key="id"
                  show-empty
                  empty-text="No payments found"
                >
                  <template #cell(date)="data">
                    <b-card-text class="text-nowrap">
                      {{ data.item.date ? moment(data.item.date).utc().format('L') : 'n/a' }}
                    </b-card-text>
                  </template>
                  <template #cell(paidAt)="data">
                    <b-card-text class="text-nowrap">
                      {{ data.item.paidAt ? moment(data.item.paidAt).utc().format('L') : 'n/a' }}
                    </b-card-text>
                  </template>
                  <template #cell(amount)="data">
                    <b-card-text class="text-nowrap">
                      {{ money(data.item.amount) }}
                    </b-card-text>
                  </template>
                  <template #cell(description)="data">
                    <b-card-text>
                      {{ data.item.description }}
                    </b-card-text>
                  </template>
                  <template #cell(type)="data">
                    <b-card-text class="text-nowrap">
                      {{ title(data.item.type) }}
                    </b-card-text>
                  </template>
                  <template #cell(status)="data">
                    <b-card-text class="text-nowrap">
                      <font-awesome-icon
                        v-if="data.item.status === 'unpaid'"
                        @click="markPaymentAsPaid(data.item)"
                        class="text-success"
                        icon="fa-solid fa-money-bill-1-wave"
                      />

                      {{ title(data.item.status) }}
                    </b-card-text>
                  </template>
                </b-table-lite>
              </b-card-actions>

              <!-- Account Charges -->
              <b-card-actions title="Charges" action-collapse>
                <b-table-lite
                  ref="refAccountCharges"
                  responsive
                  :items.sync="accountData.charges"
                  :fields="[
                    { key: 'date', label: 'Scheduled', thStyle: 'width:10%' },
                    { key: 'description' },
                    { key: 'amount', thStyle: 'width:15%' },
                  ]"
                  thead-class="text-nowrap"
                  bordered
                  primary-key="id"
                  show-empty
                  empty-text="No charges found"
                >
                  <template #cell(date)="data">
                    <b-card-text class="text-nowrap">
                      {{ data.item.date ? moment(data.item.date).utc().format('L') : 'n/a' }}
                    </b-card-text>
                  </template>
                  <template #cell(amount)="data">
                    <b-card-text class="text-nowrap">
                      {{ money(data.item.amount) }}
                    </b-card-text>
                  </template>
                  <template #cell(description)="data">
                    <b-card-text>
                      {{ data.item.description }}
                    </b-card-text>
                  </template>
                </b-table-lite>
              </b-card-actions>
            </b-col>
          </b-row>
        </b-col>

        <!-- Right Col: Card -->
        <b-col cols="12" md="12" lg="3" xl="2" class="account-actions">
          <b-card>
            <!-- Button: Edit -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mb-75"
              block
              :to="{ name: 'apps-accounting-receivable-account-edit', params: { id: $route.params.id } }"
            >
              Edit
            </b-button>

            <!-- Button: Edit -->
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="info" class="mb-75" block @click="createFinancialReport">
              Create Report
            </b-button>

            <hr />

            <!-- Button: Add Payment -->
            <b-button
              v-b-toggle.sidebar-account-add-charge
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              class="mb-75"
              block
            >
              Add Charge
            </b-button>

            <!-- Button: Add Payment -->
            <b-button
              v-b-toggle.sidebar-account-add-payment
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mb-75"
              block
            >
              Add Payment
            </b-button>

            <!-- Button: Add Refund -->
            <b-button
              v-b-toggle.sidebar-account-add-refund
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="mb-75"
              block
            >
              Add Refund
            </b-button>

            <component is="div" v-if="accountData.id && !accountData.profile">
              <hr />
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                class="mb-75"
                block
                @click="isAssignProfileOpen = true"
              >
                Assign Profile
              </b-button>
            </component>
          </b-card>
        </b-col>
      </b-row>

      <account-sidebar-add-payment :account-id.sync="accountData.id" @hide="fetchAccount" />
      <account-sidebar-add-charge :account-id.sync="accountData.id" @hide="fetchAccount" />
      <account-sidebar-add-refund :account-id.sync="accountData.id" @hide="fetchAccount" />

      <assign-account-profile
        v-on:refresh="fetchAccount"
        v-if="accountData && isAssignProfileOpen"
        :is-assign-profile-open.sync="isAssignProfileOpen"
        :student-data.sync="studentData"
        :account-data.sync="accountData"
      />
    </section>
  </div>
</template>

<script>
import { ref, onUnmounted, computed } from '@vue/composition-api';
import store from '@/store';
import router from '@/router';
import { BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BTable, BBadge } from 'bootstrap-vue';
import Logo from '@core/layouts/components/Logo.vue';
import Ripple from 'vue-ripple-directive';
import moment from 'moment';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue';

import AccountSidebarAddPayment from '../../components/AccountSidebarAddPayment.vue';
import AccountSidebarAddCharge from '../../components/AccountSidebarAddCharge.vue';
import AccountSidebarAddRefund from '../../components/AccountSidebarAddRefund.vue';
import { title } from '@core/utils/filter';
import AssignAccountProfile from '@/views/apps/accounting/receivable/account/view/components/AssignAccountProfile.vue';

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    AssignAccountProfile,
    BRow,
    BBadge,
    BCol,
    BCard,
    BCardBody,
    BCardActions,
    BTable,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,

    StatisticCardHorizontal,

    Logo,
    AccountSidebarAddPayment,
    AccountSidebarAddCharge,
    AccountSidebarAddRefund,
  },
  data: () => {
    return {
      isAssignProfileOpen: false,
    };
  },
  methods: {
    createFinancialReport() {
      console.log(`creating report for account`, this.accountData);

      store
        .dispatch('receivableStoreModule/createReportFromAccount', {
          accountId: this.accountData.id,
        })
        .then((response) => {
          const report = response.data;

          this.$router.push({ name: 'apps-report-preview', params: { id: report.id } });
        })
        .catch((error) => {
          if (error.response.status === 404) {
          }
        });
    },
    markPaymentAsPaid(payment) {
      payment.status = 'paid';

      const pmts = this.accountData.payments.map((z) => {
        if (z._id !== payment._id) return z;
        return { ...z, status: 'paid', paidAt: moment() };
      });

      const pl = { payments: pmts };

      store
        .dispatch('receivableStoreModule/updateAccount', {
          id: router.currentRoute.params.id,
          accountData: pl,
        })
        .then((response) => {
          this.accountData = response.data;

          console.log(`fX`, response.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
          }
        });
    },
    money(v) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(v);
    },
    tablePaymentPastDue(item, type) {
      if (!item || type !== 'row') return;

      if (item.date && moment(item.date) < moment() && item.status === 'unpaid') {
        return 'table-primary';
      }
    },
    displayAccountStatus() {
      if (!(this.accountData && this.accountData.payments)) {
        return { color: 'info', icon: 'fa-solid fa-spinner', text: 'loading' };
      }

      if (this.accountData.balance === 0) {
        return { color: 'success', icon: 'fa-solid fa-money-bill-trend-up', text: 'Paid' };
      }

      const pastDue = this.accountData.payments.filter((pmt) => {
        return moment(pmt.date) < moment() && pmt.status === 'unpaid';
      });

      const pastDueTotal = pastDue.map((z) => z.amount).reduce((a, b) => a + b, 0);

      if (pastDueTotal > 0) {
        return { color: 'danger', icon: 'fa-brands fa-creative-commons-nc', text: `Past Due` };
      }

      // fa-solid fa-dollar-sign
      return { color: 'success', icon: 'fa-solid fa-check', text: 'Up-to-Date' };
    },
  },
  setup() {
    const accountData = ref({});
    const studentData = ref({ profile: { secret: {} } });
    const profileData = ref({});
    const cohortData = ref({});

    const fetchAccount = async () => {
      store
        .dispatch('receivableStoreModule/fetchAccount', {
          id: router.currentRoute.params.id,
          queryParams: { populate: 'student cohort profile profile.session', secret: true, ssn: 'masked' },
        })
        .then((response) => {
          const { data } = response;
          accountData.value = data;
          studentData.value = data.student || { profile: { secret: {} } };
          profileData.value = data.profile || {};
          cohortData.value = data.cohort || {};
        })
        .catch((error) => {
          if (error.response.status === 404) {
            accountData.value = undefined;
          }
        });
    };
    fetchAccount();

    const printAccount = () => {
      window.print();
    };

    return {
      accountData,
      studentData,
      profileData,
      cohortData,

      printAccount,
      moment,
      title,
      fetchAccount,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-accounting-receivable-account.scss';
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Account Specific Styles
  .account-preview-wrapper {
    .row.account-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .account-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .account-actions {
      display: none;
    }
  }
}
</style>
