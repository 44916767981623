<template>
  <b-sidebar id="sidebar-account-add-refund" sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right>
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">Add Refund</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- Body -->
      <b-form class="p-2" @submit.prevent>
        <!-- Payment Date -->
        <b-form-group label="Refund Date (scheduled for)" label-for="payment-date">
          <flat-pickr
            v-model="addRefundData.date"
            class="form-control"
            :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'Z' }"
          />
        </b-form-group>

        <!-- Payment Amount -->
        <b-form-group label="Payment Amount" label-for="payment-amount">
          <cleave
            id="payment-amount"
            v-model="addRefundData.amount"
            class="form-control"
            :raw="true"
            :options="{ numeral: true, numeralThousandsGroupStyle: 'thousand' }"
            required
          />
        </b-form-group>

        <!-- Internal Payment Note -->
        <b-form-group label="Line Item Description" label-for="internal-payment-description">
          <b-form-input id="internal-payment-description" v-model="addRefundData.description" placeholder="Payment Description" trim />
        </b-form-group>

        <!-- Internal Payment Note -->
        <b-form-group label="Internal Payment Note" label-for="internal-payment-note">
          <b-form-textarea id="internal-payment-notes" v-model="addRefundData.note" placeholder="Internal Notes" trim />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="addRefund"
            :disabled="isLoading"
          >
            Create
          </b-button>
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="hide"> Cancel </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton } from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import Ripple from 'vue-ripple-directive';
import flatPickr from 'vue-flatpickr-component';
import vSelect from 'vue-select';
import Cleave from 'vue-cleave-component';
import useAccountsList from '../account/list/useAccountsList';
import store from '@/store';

import { onUnmounted } from '@vue/composition-api/dist/vue-composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import { useToast } from 'vue-toastification/composition';
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,

    Cleave,
    flatPickr,
    vSelect,
  },
  props: {
    accountId: {
      type: String,
    },
  },
  directives: {
    Ripple,
  },
  setup(props, { emit }) {
    const { paymentTypeOptions, paymentStatusOptions } = useAccountsList();
    const toast = useToast();

    const isLoading = ref(false);

    const addRefundData = ref({
      date: new Date(),
      description: `Manual Refund`,
      amount: 0,
      note: '',
    });

    const addRefund = async () => {
      const refund = addRefundData.value;

      isLoading.value = true;

      // lol
      refund.amount = Number(refund.amount);

      store
        .dispatch('receivableStoreModule/addRefundToAccount', {
          accountId: props.accountId,
          refundData: refund,
        })
        .then((response) => {
          emit('hide');
          isLoading.value = false;
        })
        .catch(() => {
          isLoading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error submitting account refund',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    };

    return {
      paymentTypeOptions,
      paymentStatusOptions,
      addRefundData,
      addRefund,
      isLoading,
    };
  },
};
</script>
