<template>
  <b-sidebar id="sidebar-account-add-charge" sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right>
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">Add Charge</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- Body -->
      <b-form class="p-2" @submit.prevent>
        <!-- Charge Date -->
        <b-form-group label="Charge Date" label-for="charge-date">
          <b-form-datepicker
            class="w-100"
            :value="addChargeData.date"
            @input="(val) => (addChargeData.date = val)"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="en"
          />
        </b-form-group>

        <!-- Charge Amount -->
        <b-form-group label="Charge Amount" label-for="charge-amount">
          <cleave
            id="charge-amount"
            v-model="addChargeData.amount"
            class="form-control"
            :raw="true"
            :options="{ numeral: true, numeralThousandsGroupStyle: 'thousand' }"
            required
          />
        </b-form-group>

        <!-- Internal Charge Note -->
        <b-form-group label="Internal Charge Note" label-for="internal-charge-note">
          <b-form-input id="internal-charge-description" v-model="addChargeData.description" placeholder="Charge Description" trim />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="addCharge"
            :disabled="isLoading"
          >
            Create
          </b-button>
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="hide"> Cancel </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormDatepicker } from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import Ripple from 'vue-ripple-directive';
import flatPickr from 'vue-flatpickr-component';
import vSelect from 'vue-select';
import Cleave from 'vue-cleave-component';
import useAccountsList from '../account/list/useAccountsList';
import store from '@/store';

import { onUnmounted } from '@vue/composition-api/dist/vue-composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import { useToast } from 'vue-toastification/composition';
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormDatepicker,

    Cleave,
    flatPickr,
    vSelect,
  },
  props: {
    accountId: {
      type: String,
    },
  },
  directives: {
    Ripple,
  },
  setup(props, { emit }) {
    const { statusOptions } = useAccountsList();
    const toast = useToast();

    const isLoading = ref(false);

    const addChargeData = ref({
      date: new Date(),
      description: `Manual Charge`,
      amount: 0,
    });

    const addCharge = async () => {
      isLoading.value = true;

      store
        .dispatch('receivableStoreModule/addChargeToAccount', {
          accountId: props.accountId,
          chargeData: addChargeData.value,
        })
        .then((response) => {
          emit('hide');
          isLoading.value = false;
        })
        .catch(() => {
          isLoading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error submitting account charge',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    };

    return {
      statusOptions,
      addChargeData,
      addCharge,
      isLoading,
    };
  },
};
</script>
